import React from "react";
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby";
import styled from "styled-components";
import { ReactComponent as LogoSvg } from "../svg/logo.svg";
import { ReactComponent as IwdLogoSvg } from "../svg/iwd-logo.svg";
import SocialIcon from "../components/socialIcon";
import { Container } from "./container";
import { fancyLinkStyles } from "../tokens/utils";
import { transparentize } from "polished";

const Footer = () => {
  const { col1, col2, legal } = useStaticQuery(
    graphql`
      query {
        col1: contentfulNavigation(slug: { eq: "footer-navigation-column-1" }) {
          navigationItems {
            ... on ContentfulPage {
              id
              title
              slug
            }
          }
        }
        col2: contentfulNavigation(slug: { eq: "footer-navigation-column-2" }) {
          navigationItems {
            ... on ContentfulPage {
              id
              title
              slug
            }
          }
        }
        legal: contentfulNavigation(slug: { eq: "footer-legal" }) {
          navigationItems {
            ... on ContentfulPage {
              id
              title
              slug
            }
          }
        }
      }
    `
  );

  const { navigationItems: col1Items } = col1;
  const { navigationItems: col2Items } = col2;
  const { navigationItems: legalItems } = legal;

  return (
    <FooterWrapper>
      <Container>
        <LogosContainer>
          <Logo />
          <IWDLogo />
        </LogosContainer>
        <FlexContainer>
          <Column>
            <ColumnHeading>IWD Banbury</ColumnHeading>
            <LinksList>
              {col1Items.map((item) => {
                const { id, title, slug } = item;
                return (
                  <LinksListItem key={id}>
                    <Link to={`/${slug}/`}>{title}</Link>
                  </LinksListItem>
                );
              })}
            </LinksList>
          </Column>
          <Column>
            <ColumnHeading>Events</ColumnHeading>
            <LinksList>
              {/* {col2Items.map(item => {
                console.log(item);
                const {
                  id,
                  name = null,
                  title = null,
                  slug,
                  page = null,
                  alternativeName = null,
                } = item;
                const { slug: pageSlug } = page && page.length > 0 && page[0].slug;

                const itemLink = pageSlug
                  ? `/${pageSlug}/${slug}/`
                  : `/${slug}/`;
                return (
                  <LinksListItem key={id}>
                    <Link to={itemLink} key={id}>
                      {name} - &ldquo;{alternativeName}&rdquo;
                      Hello
                    </Link>
                  </LinksListItem>
                );
              })} */}
              {col2Items.map((item) => {
                const {
                  id,
                  name = null,
                  title = null,
                  slug,
                  page = null,
                  alternativeName = null,
                } = item;
                const pageSlug = page && page.length > 0 && page[0].slug;
                const itemLink = pageSlug
                  ? `/${pageSlug}/${slug}/`
                  : `/${slug}/`;
                const itemText = title
                  ? title
                  : `${name} - “${alternativeName}”`;
                return (
                  <LinksListItem key={id}>
                    <Link to={itemLink}>{itemText}</Link>
                  </LinksListItem>
                );
              })}
            </LinksList>
          </Column>
          <Column>
            <ColumnHeading>Policies</ColumnHeading>
            <LinksList>
              {legalItems.map((item) => {
                const { id, title, slug } = item;
                return (
                  <LinksListItem key={id}>
                    <Link to={`/${slug}/`}>{title}</Link>
                  </LinksListItem>
                );
              })}
            </LinksList>
            <TimingsMessage>
              Please note that some event times are currently subject to change.
            </TimingsMessage>
          </Column>
          <Column>
            <ColumnHeading>Social</ColumnHeading>
            <SocialLinksList>
              <SocialLinksListItem>
                <SocialLink href="https://www.instagram.com/IWD.Banbury">
                  <StyledSocialIcon type="instagram" />
                  IWD.Banbury
                </SocialLink>
              </SocialLinksListItem>
              <SocialLinksListItem>
                <SocialLink href="https://www.twitter.com/IWDBanbury">
                  <StyledSocialIcon type="twitter" />
                  @IWDBanbury
                </SocialLink>
              </SocialLinksListItem>
              <SocialLinksListItem>
                <SocialLink href="https://www.facebook.com/IWDBanbury">
                  <StyledSocialIcon type="facebook" />
                  IWDBanbury
                </SocialLink>
              </SocialLinksListItem>
              <SocialLinksListItem>
                <SocialLink href="https://www.linkedin.com/company/IWDBanbury">
                  <StyledSocialIcon type="linkedin" />
                  IWDBanbury
                </SocialLink>
              </SocialLinksListItem>
            </SocialLinksList>
          </Column>
        </FlexContainer>
        <FlexContainer>
          <Column>
            <Copyright>
              © {new Date().getFullYear()} Created by{" "}
              <CopyrightLink href="https://www.corseconcierge.com">
                Corse Concierge
              </CopyrightLink>
            </Copyright>
          </Column>
        </FlexContainer>
      </Container>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  padding-top: 0.5rem;
  padding-bottom: 2rem;
  border-top: 1px solid ${({ theme }) => theme.palette.oxfordshireBlue};
  background-color: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.oxfordshireBlue};
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;

  @media (min-width: 32em) {
    margin-right: -2rem;
    margin-left: -2rem;
  }
`;

const LogosContainer = styled(FlexContainer)`
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  flex-grow: ${({ noGrow }) => (noGrow ? "0" : "1")};
  padding-right: 1rem;
  padding-left: 1rem;

  @media (min-width: 32em) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

const ColumnHeading = styled.h4`
  margin: 1.5rem 0 0;
`;

const LinksList = styled.ul`
  margin: 0.5rem 0 0;
  padding-left: 0;
  list-style: none;
  max-width: 19ch;
`;

const LinksListItem = styled.li`
  & + & {
    margin-top: 0.5em;
  }
`;

const Link = styled(GatsbyLink)`
  ${fancyLinkStyles(
    ({ theme }) => transparentize(0.6, theme.palette.suffrageGreen),
    ({ theme }) => theme.palette.suffrageGreen,
    "0.1em"
  )};

  line-height: 1.2;
  text-decoration: none;
`;

const TimingsMessage = styled.p`
  max-width: 30ch;
  font-size: 0.8em;
`;

const Logo = styled(LogoSvg)`
  display: block;
  padding-top: 1.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 300px;

  @media (min-width: 32em) {
    padding-right: 2rem;
    padding-left: 2rem;
    width: 360px;
  }
`;

const IWDLogo = styled(IwdLogoSvg)`
  display: block;
  padding-top: 1.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 100%;
  max-width: 360px;

  @media (min-width: 32em) {
    padding-right: 2rem;
    padding-left: 2rem;
    max-width: 420px;
  }
`;

const SocialLinksList = styled.ul`
  margin-top: 0.5rem;
  padding-left: 0;
  list-style: none;
`;

const SocialLinksListItem = styled.li`
  & + & {
    margin-top: 0.5em;
  }
`;

const SocialLink = styled.a`
  ${fancyLinkStyles(
    ({ theme }) => transparentize(0.6, theme.palette.suffrageGreen),
    ({ theme }) => theme.palette.suffrageGreen,
    "0.15em"
  )};
  display: inline-flex;
  align-items: center;
  padding: 0.2em;
  font-size: 0.8em;
  font-weight: 500;
  text-decoration: none;
`;

const StyledSocialIcon = styled(SocialIcon)`
  width: 1rem;
  margin-right: 0.5rem;
`;

const Copyright = styled.p`
  margin-top: 1.5rem;
  font-weight: 500;
`;

const CopyrightLink = styled.a`
  ${fancyLinkStyles(
    ({ theme }) => transparentize(0.6, theme.palette.suffrageGreen),
    ({ theme }) => theme.palette.suffrageGreen,
    "0.15em"
  )};

  text-decoration: none;
`;

export default Footer;
