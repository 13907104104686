import React from "react";
import { ReactComponent as FacebookIcon } from "../svg/facebook.svg";
import { ReactComponent as InstagramIcon } from "../svg/instagram.svg";
import { ReactComponent as TwitterIcon } from "../svg/twitter.svg";
import { ReactComponent as LinkedinIcon } from "../svg/linkedin.svg";
import { ReactComponent as GlobeIcon } from "../svg/globe.svg";
import { ReactComponent as TiktokIcon } from "../svg/tiktok.svg";

function SocialIcon({ type, className }) {
  switch (type) {
    case "facebook":
      return <FacebookIcon className={className} />;
    case "instagram":
      return <InstagramIcon className={className} />;
    case "twitter":
      return <TwitterIcon className={className} />;
    case "linkedin":
      return <LinkedinIcon className={className} />;
    case "tiktok":
      return <TiktokIcon className={className} />;
    case "website":
    default:
      return <GlobeIcon className={className} />;
  }
}

export default SocialIcon;
